import { API_COOKIE, COMPANY, COOKIE_CATEGORY } from 'common/constants'
import { Cookie } from 'common/models/cookie-category'

export class CookieService {
  constructor(private company: COMPANY) {}

  async getCookieList(category: COOKIE_CATEGORY): Promise<Cookie[]> {
    try {
      const response = await fetch(`${API_COOKIE}/cookies/description/all?category=${category}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          company: this.company
        }
      })
      const data = await response.json()
      return data
    } catch {
      return []
    }
  }
}
