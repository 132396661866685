import Cookies from 'js-cookie'

import { LOCAL_CACHE_POLICY_TIME_MINUTES } from 'common/constants'
import { dispatchCookieSettingsEvent } from 'common/event'
import { CookiePolicy } from 'common/models/cookie-policy-config'
import { getCookiePolicyByCountry } from 'common/request'
import { LocationService } from 'common/service/LocationService'

export class CookiePolicyService {
  locationService: LocationService

  constructor(service: LocationService) {
    this.locationService = service
  }

  getDefaultValue(countryCode: string): CookiePolicy {
    return {
      countryCode,
      countryName: countryCode,
      config: {
        enableCookieBanner: false,
        enableConfigure: false,
        noEssentialCookiesEnableByDefault: false
      }
    }
  }

  localCacheIsExpire(processDateTime: number): boolean {
    const now = Date.now()
    const diffInMilliSeconds = Math.abs(now - processDateTime) / 1000
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60

    return minutes >= LOCAL_CACHE_POLICY_TIME_MINUTES
  }

  getLocalSavedPolicy(): CookiePolicy | null {
    const dataStr = localStorage.getItem('local-saved-policy')

    if (dataStr) {
      const data = JSON.parse(dataStr)

      if (!this.localCacheIsExpire(data.saveDate)) return data.policy
    }
    return null
  }

  async getCookiePolicy(cookieSettingsName: string, cookieConsentName: string, domain: string): Promise<CookiePolicy> {
    const localPolicy = this.getLocalSavedPolicy()

    if (localPolicy) return localPolicy

    const countryCode = await this.locationService.getCountry()

    try {
      if (countryCode !== 'unknown') {
        const policy = await getCookiePolicyByCountry(countryCode)

        localStorage.setItem(
          'local-saved-policy',
          JSON.stringify({
            saveDate: Date.now(),
            locationCountryCode: countryCode,
            policy
          })
        )

        this.updateCookieSettingsWithPolicy(cookieSettingsName, cookieConsentName, domain, policy)

        return policy
      }

      const defaultPolicy = this.getDefaultValue(countryCode)
      this.updateCookieSettingsWithPolicy(cookieSettingsName, cookieConsentName, domain, defaultPolicy)

      return defaultPolicy
    } catch {
      const defaultPolicy = this.getDefaultValue(countryCode)
      this.updateCookieSettingsWithPolicy(cookieSettingsName, cookieConsentName, domain, defaultPolicy)

      return defaultPolicy
    }
  }

  private updateCookieSettingsWithPolicy(
    cookieSettingsName: string,
    cookieConsentName: string,
    domain: string,
    cookiePolicy: CookiePolicy
  ): void {
    const currentCookieSettings = Cookies.get(cookieSettingsName)
    if (currentCookieSettings) {
      const { enableSettings: previousEnableSettings } = JSON.parse(currentCookieSettings)
      const { enableConfigure: newEnableSettings } = cookiePolicy.config

      if (previousEnableSettings !== newEnableSettings) {
        Cookies.remove(cookieConsentName)
      }
    }

    const cookieSettingsData = {
      enableSettings: cookiePolicy.config.enableConfigure
    }

    Cookies.set(cookieSettingsName, JSON.stringify(cookieSettingsData), {
      domain
    })

    dispatchCookieSettingsEvent(cookieSettingsData)
  }
}
