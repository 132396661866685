import { COOKIE_CATCHER, COOKIE_CONSENT_VERSION } from 'common/constants'
import { CookieCrawler } from 'common/models/cookie-crawler'

type LocalCrawlerConfig = {
  lastProcessDateTime: string | Date
  version: string
}

const buildDateDaysBefore = (initialDate: Date, daysToSubtract: number): Date => {
  const sevenDaysInMilliseconds = daysToSubtract * 24 * 60 * 60 * 1000

  return new Date(initialDate.getTime() - sevenDaysInMilliseconds)
}

const isProcessDateMoreThanAWeek = (processDateString: string | Date): boolean => {
  const processDate = new Date(processDateString)
  const today = new Date()
  const dateInterval = processDate.getTime() - buildDateDaysBefore(today, 7).getTime()
  const dateIntervalInDays = dateInterval / 24 / 60 / 60 / 1000

  return dateIntervalInDays < 0
}

const registerProcessDate = (cookieCatcherConfig: LocalCrawlerConfig): void => {
  cookieCatcherConfig = {
    ...cookieCatcherConfig,
    lastProcessDateTime: new Date(),
    version: COOKIE_CONSENT_VERSION
  }
  localStorage.setItem(COOKIE_CATCHER, JSON.stringify(cookieCatcherConfig))
}

type ProcessingCatcherType = {
  config: LocalCrawlerConfig
  timeCheck: (date: string) => boolean
  getInfo: () => Promise<CookieCrawler>
  postCookies: () => false | Promise<unknown>
  saveProcessLocal: (data: LocalCrawlerConfig) => void
}
const processingCatcher = async ({
  config,
  timeCheck,
  getInfo,
  postCookies,
  saveProcessLocal
}: ProcessingCatcherType): Promise<void> => {
  if (timeCheck(config.lastProcessDateTime as string)) {
    const crawler = await getInfo()

    if (crawler.enable) {
      await postCookies()
    }
    saveProcessLocal(config)
  }
}

export { buildDateDaysBefore, isProcessDateMoreThanAWeek, registerProcessDate, processingCatcher }
