import { COMPANY } from 'common/constants'
import { ConsentService } from 'common/service/ConsentService'
import { CookieService } from 'common/service/CookieService'
import { DomainService } from 'common/service/DomainService'
import { HotmartAlert, HotmartBannerContainer, HotmartConfirmModal } from 'cookie-banner/implementations'
import { createApp } from 'cookie-banner/implementations/appFactory'

export const run = async (): Promise<void> => {
  const company = COMPANY.HOTMART

  const windowLocation = window.location
  const domainService = new DomainService()
  const consentService = new ConsentService(windowLocation, domainService)
  const cookieService = new CookieService(company)

  const alert = new HotmartAlert(consentService)
  const modal = new HotmartConfirmModal(consentService, cookieService)
  const container = new HotmartBannerContainer()

  const app = createApp(consentService, container, alert, modal, company)
  await app.run()
}
;(async (): Promise<void> => await run())()
