// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hotmart-cookie-modal-configuration  {
  z-index: 1040;
  overflow-y: auto;
  padding: 2rem;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  max-height: 85vh;
  min-width: 30rem;

  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
}

.cookie-banner-modal-body {
  display: flex;
  flex-direction: column;
  overflow: auto;

  padding: 0.5rem;
  margin-bottom: 2rem;
}

.cookie-banner-categories-list{
  padding: 0;
}

.cookie-banner-categories-item {
  padding: 1rem;
}

.cookie-banner-categories-list {
  list-style-type: none;
}


@media only screen and (max-width: 600px) {
  .hotmart-cookie-modal-configuration  {
    z-index: 1040;
    overflow-y: auto;
    padding: 2rem;

    height: 70%;
    width: 70%;

    max-height: inherit;
    min-width: auto;
  }
}

`, "",{"version":3,"sources":["webpack://./src/cookie-banner/styles/base-modal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,4BAA4B;EAC5B,gBAAgB;EAChB,gBAAgB;;EAEhB,eAAe;EACf,SAAS;EACT,QAAQ;EACR,gCAAgC;;EAEhC,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;;EAEd,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;;AAGA;EACE;IACE,aAAa;IACb,gBAAgB;IAChB,aAAa;;IAEb,WAAW;IACX,UAAU;;IAEV,mBAAmB;IACnB,eAAe;EACjB;AACF","sourcesContent":[".hotmart-cookie-modal-configuration  {\n  z-index: 1040;\n  overflow-y: auto;\n  padding: 2rem;\n  -ms-scroll-chaining: none;\n  overscroll-behavior: contain;\n  max-height: 85vh;\n  min-width: 30rem;\n\n  position: fixed;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n\n  display: flex;\n  flex-direction: column;\n}\n\n.cookie-banner-modal-body {\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n\n  padding: 0.5rem;\n  margin-bottom: 2rem;\n}\n\n.cookie-banner-categories-list{\n  padding: 0;\n}\n\n.cookie-banner-categories-item {\n  padding: 1rem;\n}\n\n.cookie-banner-categories-list {\n  list-style-type: none;\n}\n\n\n@media only screen and (max-width: 600px) {\n  .hotmart-cookie-modal-configuration  {\n    z-index: 1040;\n    overflow-y: auto;\n    padding: 2rem;\n\n    height: 70%;\n    width: 70%;\n\n    max-height: inherit;\n    min-width: auto;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
