import pjson from '../../package.json'

export enum COMPANY {
  HOTMARTCO = 'HOTMARTCO',
  HOTMART = 'HOTMART',
  TEACHABLE = 'TEACHABLE',
  ENOTAS = 'ENOTAS'
}

export enum COOKIE_CATEGORY {
  ESSENTIAL = 'ESSENTIAL',
  ADVERTISING = 'ADVERTISING'
}

export const DEV_COOKIE_CONSENT = 'devCookieConsent'
export const DEV_COOKIE_SETTINGS = 'devCookieSettings'
export const HOTMART_COOKIE_CONSENT = 'hmCookieConsent'
export const HOTMART_COOKIE_SETTINGS = 'hmCookieSettings'
export const KLICKPAGES_COOKIE_CONSENT = 'kpCookieConsent'
export const KLICKPAGES_COOKIE_SETTINGS = 'kpCookieSettings'
export const TEACHABLE_COOKIE_CONSENT = 'teCookieConsent'
export const TEACHABLE_COOKIE_SETTINGS = 'teCookieSettings'
export const HOTMART_CO_COOKIE_CONSENT = 'coCookieConsent'
export const HOTMART_CO_COOKIE_SETTINGS = 'coCookieSettings'
export const ENOTAS_COOKIE_CONSENT = 'enCookieConsent'
export const ENOTAS_COOKIE_SETTINGS = 'enCookieSettings'
export const TEACHABLE_9_5_CONSENT = 'te95quittersCookieConsent'
export const TEACHABLE_9_5_SETTINGS = 'te95quittersCookieSettings'

export const COOKIE_CATCHER = 'cookieCatcher'

export const COOKIE_AB_EXPIRES = 2

export const TWELVE_MONTHS_IN_DAYS = 365
export const RESEARCH_CATEGORIZATION = 7 * 24 * 60 * 60 * 1000

export const COOKIE_CONSENT_VERSION = '0.0.3'
export const COOKIE_PACKAGE_VERSION = pjson.version

export const IP_API = 'https://api-ip.hotmart.com/api/ip/info'
export const API_COOKIE = process.env.API_COOKIE
export const LOCAL_CACHE_POLICY_TIME_MINUTES = 1

export const iconTimes =
  '<svg width=\'24\' height=\'24\' viewBox="0 0 320 512" fill=\'none\' xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"></path></svg>'

export const DOMAIN_DATA = [
  {
    pattern: 'localhost$',
    cookieName: DEV_COOKIE_CONSENT,
    cookieSettingsName: DEV_COOKIE_SETTINGS
  },
  {
    pattern: 'buildstaging.com$',
    cookieName: HOTMART_COOKIE_CONSENT,
    cookieSettingsName: HOTMART_COOKIE_SETTINGS,
    domain: '.buildstaging.com'
  },
  {
    pattern: 'hotmart.com$',
    cookieName: HOTMART_COOKIE_CONSENT,
    cookieSettingsName: HOTMART_COOKIE_SETTINGS,
    domain: '.hotmart.com'
  },
  {
    pattern: 'hotmart.co$',
    cookieName: HOTMART_CO_COOKIE_CONSENT,
    cookieSettingsName: HOTMART_CO_COOKIE_SETTINGS,
    domain: '.hotmart.co'
  },
  {
    pattern: 'zeachable.com$',
    cookieName: TEACHABLE_COOKIE_CONSENT,
    cookieSettingsName: TEACHABLE_COOKIE_SETTINGS,
    domain: '.zeachable.com'
  },
  {
    pattern: 'zeachable.cloud$',
    cookieName: TEACHABLE_COOKIE_CONSENT,
    cookieSettingsName: TEACHABLE_COOKIE_SETTINGS,
    domain: '.zeachable.cloud'
  },
  {
    pattern: 'wpengine.com$',
    cookieName: TEACHABLE_COOKIE_CONSENT,
    cookieSettingsName: TEACHABLE_COOKIE_SETTINGS,
    domain: '.wpengine.com'
  },
  {
    pattern: 'teachable.com$',
    cookieName: TEACHABLE_COOKIE_CONSENT,
    cookieSettingsName: TEACHABLE_COOKIE_SETTINGS,
    domain: '.teachable.com'
  },
  {
    pattern: 'spotlightapp.io$',
    cookieName: TEACHABLE_COOKIE_CONSENT,
    cookieSettingsName: TEACHABLE_COOKIE_SETTINGS,
    domain: '.spotlightapp.io'
  },
  {
    pattern: 'webflow.io$',
    cookieName: TEACHABLE_COOKIE_CONSENT,
    cookieSettingsName: TEACHABLE_COOKIE_SETTINGS,
    domain: '.webflow.io'
  },
  {
    pattern: 'klickpages.com.br$',
    cookieName: KLICKPAGES_COOKIE_CONSENT,
    cookieSettingsName: KLICKPAGES_COOKIE_SETTINGS,
    domain: '.klickpages.com.br'
  },
  {
    pattern: 'klicksend',
    cookieName: KLICKPAGES_COOKIE_CONSENT,
    cookieSettingsName: KLICKPAGES_COOKIE_SETTINGS,
    domain: '.klickpages.com.br'
  },
  {
    pattern: 'enotas.com.br$',
    cookieName: ENOTAS_COOKIE_CONSENT,
    cookieSettingsName: ENOTAS_COOKIE_SETTINGS,
    domain: '.enotas.com.br'
  },
  {
    pattern: '9to5quittersclub.com',
    cookieName: TEACHABLE_9_5_CONSENT,
    cookieSettingsName: TEACHABLE_9_5_SETTINGS,
    domain: '.9to5quittersclub.com'
  }
]

export const possibleScriptNames: Record<string, Array<string>> = {
  [COMPANY.HOTMART]: ['/cookie-policy/app.js', '/cookie-policy/hotpay.js'],
  [COMPANY.TEACHABLE]: ['/cookie-policy/teachable.js', '/cookie-policy/spotlight.js'],
  [COMPANY.HOTMARTCO]: ['/cookie-policy/hotmartco.js'],
  [COMPANY.ENOTAS]: ['/cookie-policy/enotas.js']
}
