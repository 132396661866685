import BannerContainerBase from 'cookie-banner/base/BannerContainerBase'

export class HotmartBlogBannerContainer extends BannerContainerBase {
  override loadFont(): void {
    if (!document.fonts.check('16px Nunito Sans')) {
      this.container.innerHTML =
        '<link href="https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,700" rel="stylesheet">'
    }
  }
}

customElements.define('hotmart-blog-cookie-policy', HotmartBlogBannerContainer)
