import BannerContainerBase from 'cookie-banner/base/BannerContainerBase'

export class SpotlightBannerContainer extends BannerContainerBase {
  override loadFont(): void {
    if (!document.fonts.check('16px Inter')) {
      this.container.innerHTML =
        '<link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap" rel="stylesheet">'
    }
  }
}

customElements.define('spotlight-cookie-policy', SpotlightBannerContainer)
