export default abstract class BannerContainerBase extends HTMLElement {
  protected container: HTMLDivElement
  constructor() {
    super()
    this.container = document.createElement('div')
    this.build()
  }

  private baseStyle = (): HTMLStyleElement => {
    const style = document.createElement('style')
    style.textContent = `
      #hotmart-cookie_alert-component {
        max-width: 960px;
        z-index: 9999999 !important;
        position: fixed;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 0;

        font-family: var(--font-family);
      }
    `
    return style
  }

  protected loadFont?(): void

  build(): void {
    this.container.id = 'hotmart-cookie-policy'
    this.container.classList.add('hotmart-cookie-policy-container')
    this.container.style.zIndex = '999999'
    this.container.style.textAlign = 'initial'
    const shadow = this.container.attachShadow({ mode: 'open' })
    shadow.appendChild(this.baseStyle())
    this.loadFont && this.loadFont()
    this.append(this.container)
  }

  addChild(children: HTMLElement): void {
    this.container.shadowRoot?.appendChild(children)
  }

  getContainer(): HTMLDivElement {
    return this.container
  }
}
