// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Cosmos Design System - Design Tokens
 * Version: 0.0.9
 * Do not edit directly
 */

:host {
  --color-primary-100: #ffd3c8;
  --color-primary-200: #ffc4b6;
  --color-primary-300: #ffb5a3;
  --color-primary-400: #ffa791;
  --color-primary-500: #ff987f;
  --color-primary-600: #ff896d;
  --color-primary-700: #ff7b5b;
  --color-neutral-100: #e5e5e5;
  --color-neutral-200: #e6e9ed;
  --color-neutral-300: #c9ced4;
  --color-neutral-400: #9ea4ac;
  --color-neutral-500: #474755;
  --color-neutral-600: #131313;
  --color-neutral-700: #161616;
  --color-neutral-800: #1d1d1d;
  --color-neutral-900: #1e1d1d;
  --color-neutral-1000: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/cookie-banner/implementations/enotas/enotas-tokens.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,4BAA4B;EAC5B,6BAA6B;AAC/B","sourcesContent":["/**\n * Cosmos Design System - Design Tokens\n * Version: 0.0.9\n * Do not edit directly\n */\n\n:host {\n  --color-primary-100: #ffd3c8;\n  --color-primary-200: #ffc4b6;\n  --color-primary-300: #ffb5a3;\n  --color-primary-400: #ffa791;\n  --color-primary-500: #ff987f;\n  --color-primary-600: #ff896d;\n  --color-primary-700: #ff7b5b;\n  --color-neutral-100: #e5e5e5;\n  --color-neutral-200: #e6e9ed;\n  --color-neutral-300: #c9ced4;\n  --color-neutral-400: #9ea4ac;\n  --color-neutral-500: #474755;\n  --color-neutral-600: #131313;\n  --color-neutral-700: #161616;\n  --color-neutral-800: #1d1d1d;\n  --color-neutral-900: #1e1d1d;\n  --color-neutral-1000: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
