import { CookiePolicyConfig } from 'common/models/cookie-policy-config'
import AlertBase from 'cookie-banner/base/AlertBase'
import { t } from 'cookie-banner/i18n'
import { loadCosmosAlert } from 'cookie-banner/styles'

export class HotmartAlert extends AlertBase {
  override async loadCompanyStyle(): Promise<void> {
    await loadCosmosAlert()
  }

  override render(cookiePolicyConfig: CookiePolicyConfig): void {
    const { enableConfigure } = cookiePolicyConfig
    const confirmButtonText = enableConfigure ? t('alert_accept_btn') : t('alert_ok_btn')

    this.root.innerHTML = `
      <div class='cookie-alert-container hot-container cookie-policy-background _rounded-lg _shadow _p-5'>
        <div>
          <div class='cookie-alert-container-header _d-flex _justify-content-between _align-items-start _mb-3'>
            <p class="cookie-alert-container-header _text-gray-700 _mb-0 _text-4 _font-weight-light">
              ${t('alert_title')}
            </p>
          </div>
          <div class='cookie-alert-container-body-text cookie-alert-container-body _mb-5'>
            <p class='_text-gray-500 _mb-0 _font-weight _line-height-lg _text-2'>
            ${t('alert_text')} ${
      !enableConfigure ? `${t('alert_accept_all_description', { confirmButtonText })}` : ''
    } ${t('alert_more_information', { ...this.bannerLinks() })}
            </p>
          </div>
          <div class='cookie-policy-footer _h-auto _d-flex _justify-content-end _align-items-center'>
            <button ${
              enableConfigure ? '' : 'hidden'
            } class='cookie-policy-button cookie-policy-go-to-configuration hot-button hot-button--primary'>${t(
      'alert_open_configuration_btn'
    )}</button>
            ${
              enableConfigure
                ? `
                  <button class="cookie-policy-button cookie-policy-accept-only-necessary hot-button hot-button--primary">${t(
                    'alert_accept_only_necessary_btn'
                  )}</button>
                  <button class="cookie-policy-button cookie-policy-accept-all hot-button hot-button--primary">${t(
                    'alert_accept_btn'
                  )}</button>
                `
                : `<button class="cookie-policy-button cookie-policy-accept-all hot-button hot-button--primary">${t(
                    'alert_ok_btn'
                  )}</button>`
            }
          </div>
        </div>
      </div>
    `
  }
}
