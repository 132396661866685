import EventAgent from '@hotmart/event-agent-js'
EventAgent.mode = (process.env.APP_STAGE as 'production') || 'staging'

export type sendEvent = {
  projectName?: string
  sessionId?: string
  action: string
  allowAdvertising: boolean
  enabledConfigure: boolean
  acceptedDate?: string
}

export const sendAgentEvent = async ({
  action,
  enabledConfigure,
  allowAdvertising,
  acceptedDate
}: sendEvent): Promise<void> => {
  if (enabledConfigure == null || allowAdvertising == null) {
    return
  }
  let countryCode = ''
  const dataPolicy = localStorage.getItem('local-saved-policy')
  if (dataPolicy) {
    const jsonPolicy = JSON.parse(dataPolicy)
    countryCode = jsonPolicy.locationCountryCode
  }

  let sessionId = ''
  let projectName = ''
  const dataSession = localStorage.getItem('cookie-application')
  if (dataSession) {
    const jsonSession = JSON.parse(dataSession)
    sessionId = jsonSession.sessionId
    projectName = jsonSession.projectName
  }

  try {
    const eventSchema = {
      system: 'app_cookie_policy',
      entity: 'user',
      action: action,
      data_version: '1.0',
      event: {
        projectName: projectName || 'app_cookie_policy',
        sessionId: sessionId || '',
        enabledConfigure: enabledConfigure,
        allowAdvertising: allowAdvertising,
        acceptedDate: acceptedDate,
        countryCode: countryCode || '',
        hostname: window.location.hostname,
        href: window.location.href
      }
    }

    if (!eventSchema.event.acceptedDate) {
      delete eventSchema.event.acceptedDate
    }

    await EventAgent.send(eventSchema)
  } catch (err) {
    console.error(err)
  }

  if (action.includes('acceptance')) {
    localStorage.removeItem('cookie-application')
  }
}
