import { DOMAIN_DATA, HOTMART_CO_COOKIE_CONSENT, HOTMART_CO_COOKIE_SETTINGS } from 'common/constants'

type DomainData = {
  pattern: string
  cookieName: string
  cookieSettingsName: string
  domain?: string
}

export class DomainService {
  static domainData(): DomainData[] {
    return DOMAIN_DATA
  }

  availableDomain(currentDomain: string): boolean {
    return !!this.getDomainData(currentDomain)
  }

  getDomainName(hostname: string): string {
    const domainData = this.getDomainData(hostname)
    return domainData?.domain || ''
  }

  getCookieNameByDomain(hostname: string): string {
    const domainData = this.getDomainData(hostname)
    return domainData?.cookieName || HOTMART_CO_COOKIE_CONSENT
  }

  getCookieSettingsNameByDomain(hostname: string): string {
    const domainData = this.getDomainData(hostname)
    return domainData?.cookieSettingsName || HOTMART_CO_COOKIE_SETTINGS
  }

  isCheckoutPage(hostname: string): boolean {
    return (
      hostname.indexOf('app-hotmart-checkout.buildstaging.com') !== -1 || hostname.indexOf('pay.hotmart.com') !== -1
    )
  }

  private getDomainData(currentDomain: string): DomainData | null {
    for (const data of DomainService.domainData()) {
      const regex = new RegExp(data.pattern)
      if (currentDomain.match(regex)) {
        return data
      }
    }
    return null
  }
}
