// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  margin: 16px auto;
  border: 4px solid var(--loader-background);
  border-top: 4px solid var(--loader-color);
  border-radius: 50%;
  width: 26px;
  height: 26px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`, "",{"version":3,"sources":["webpack://./src/cookie-banner/styles/loader.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,0CAA0C;EAC1C,yCAAyC;EACzC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".loader {\n  margin: 16px auto;\n  border: 4px solid var(--loader-background);\n  border-top: 4px solid var(--loader-color);\n  border-radius: 50%;\n  width: 26px;\n  height: 26px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
