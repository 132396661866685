export class UrlBlockedListService {
  static blockedList(): string[] {
    return [
      // staging
      'privacy.buildstaging.com/cookies',
      'app-club.buildstaging.com/t/page-embed',
      // prd
      'app-vlc.hotmart.com/signup',
      'club.hotmart.com/t/page-embed'
    ]
  }

  canShowBanner(): boolean {
    const blockedList = UrlBlockedListService.blockedList()
    const href = window.location.href

    return !blockedList.some((blockedUrl) => href.includes(blockedUrl))
  }
}
