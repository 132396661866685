import { sendAgentEvent } from 'common/agent'
import { iconTimes } from 'common/constants'
import { CookieConsentEventBody, dispatchCookieConsentEvent } from 'common/event'
import { CookiePolicyConfig } from 'common/models/cookie-policy-config'
import { ConsentService } from 'common/service/ConsentService'
import { getLang, isRtlResource, t } from 'cookie-banner/i18n'

type AlertProps = {
  cookiePolicyConfig: CookiePolicyConfig
  openModalCb: () => Promise<void>
}

export type AlertLinks = {
  cookiePolicyUrl: string
  dataPrivacyUrl: string
}

export default abstract class AlertBase {
  protected root: HTMLElement

  constructor(private consentService: ConsentService, private keepAllowAdvertising = false) {
    this.root = document.createElement('div')
    this.root.id = 'hotmart-cookie_alert-component'
    this.root.dir = isRtlResource() ? 'rtl' : 'ltr'
  }

  async build(props: AlertProps): Promise<HTMLElement> {
    this.loadCompanyStyle && (await this.loadCompanyStyle())
    this.render(props.cookiePolicyConfig)
    this.configureHandlers(props)
    this.afterRender && this.afterRender()
    return this.root
  }

  protected loadCompanyStyle?(): Promise<void>
  protected afterRender?(): void
  protected bannerLinks(): AlertLinks {
    const links: Record<string, AlertLinks> = {
      ES: {
        cookiePolicyUrl: 'https://www.hotmart.com/es/legal/politica-de-cookies',
        dataPrivacyUrl: 'https://hotmart.com/es/legal/privacidad-de-datos'
      },
      FR: {
        cookiePolicyUrl: 'https://www.hotmart.com/fr/legal/politique-de-cookies',
        dataPrivacyUrl: 'https://hotmart.com/fr/legal/politique-de-confidentialite'
      },
      PT: {
        cookiePolicyUrl: 'https://www.hotmart.com/pt-br/legal/politica-de-cookies',
        dataPrivacyUrl: 'https://www.hotmart.com/pt-br/legal/privacidade-de-dados'
      },
      EN: {
        cookiePolicyUrl: 'https://www.hotmart.com/en/legal/cookies-policy',
        dataPrivacyUrl: 'https://www.hotmart.com/en/legal/data-privacy-policy'
      }
    }
    return links[getLang()] || links['EN']
  }

  protected render(cookiePolicyConfig: CookiePolicyConfig): void {
    const { enableConfigure } = cookiePolicyConfig
    const confirmButtonText = enableConfigure ? t('alert_accept_btn') : t('alert_ok_btn')

    this.root.innerHTML = `
      <div class='cookie-alert-container'>
        <div>
          <div class='cookie-alert-container-header'>
            <p class="cookie-alert-container-title">
              ${t('alert_title')}
            </p>
            <button ${enableConfigure ? '' : 'hidden'} class='cookie-policy-close'>
              ${iconTimes}
            </button>
          </div>
          <div class='cookie-alert-container-body'>
            <p class='cookie-alert-container-body-text'>
              ${t('alert_text')} ${t('alert_accept_all_description', { confirmButtonText })} ${
      enableConfigure ? `${t('alert_accept_only_necessary_description')} ${t('alert_config_description')}` : ''
    } ${t('alert_more_information', { ...this.bannerLinks() })}
            </p>
          </div>
          <div class='cookie-policy-footer'>
            <button ${enableConfigure ? '' : 'hidden'} class='cookie-policy-button cookie-policy-go-to-configuration'>
              ${t('alert_open_configuration_btn')}
            </button>
            ${
              enableConfigure
                ? `
                  <button class="cookie-policy-button cookie-policy-accept-all">${t('alert_accept_btn')}</button>
                  <button class="cookie-policy-button cookie-policy-accept-only-necessary">${t(
                    'alert_accept_only_necessary_btn'
                  )}</button>
                `
                : `<button class="cookie-policy-button cookie-policy-accept-all cookie-policy-ok">${t(
                    'alert_ok_btn'
                  )}</button>`
            }
          </div>
        </div>
      </div>
    `
  }

  private configureHandlers({ openModalCb, cookiePolicyConfig }: AlertProps): void {
    const configureCookiePreferencesBtn = this.root.getElementsByClassName('cookie-policy-go-to-configuration')[0]
    const acceptAllCookiesBtn = this.root.getElementsByClassName('cookie-policy-accept-all')[0]
    const acceptOnlyNecessaryCookiesBtn = this.root.getElementsByClassName('cookie-policy-accept-only-necessary')[0]
    const closeCookieNoticeBtn = this.root.getElementsByClassName('cookie-policy-close')[0]

    configureCookiePreferencesBtn.addEventListener('click', async () => {
      sendAgentEvent({
        action: 'cookie_change_preferences',
        allowAdvertising: cookiePolicyConfig.noEssentialCookiesEnableByDefault,
        enabledConfigure: cookiePolicyConfig.enableConfigure
      })
      await openModalCb()
      this.root.remove()
    })

    const acceptAllCookieBtnCallback = (): void => {
      this.acceptAllCookies()
      this.handleCookieConsentEventDispatch(cookiePolicyConfig, {
        allowAdvertising: true,
        enableConfigure: cookiePolicyConfig.enableConfigure
      })
      sendAgentEvent({
        action: 'cookie_acceptance',
        allowAdvertising: true,
        enabledConfigure: cookiePolicyConfig.enableConfigure,
        acceptedDate: new Date().toISOString()
      })
    }

    const acceptOnlyNecessaryCookieBtnCallback = (): void => {
      this.acceptOnlyNecessaryCookies()
      this.handleCookieConsentEventDispatch(cookiePolicyConfig, {
        allowAdvertising: false,
        enableConfigure: cookiePolicyConfig.enableConfigure
      })
      sendAgentEvent({
        action: 'cookie_acceptance_only_necessary',
        allowAdvertising: false,
        enabledConfigure: cookiePolicyConfig.enableConfigure,
        acceptedDate: new Date().toISOString()
      })
    }

    closeCookieNoticeBtn?.addEventListener('click', () => this.root.remove())
    acceptAllCookiesBtn.addEventListener('click', () => acceptAllCookieBtnCallback())
    if (acceptOnlyNecessaryCookiesBtn) {
      acceptOnlyNecessaryCookiesBtn.addEventListener('click', () => acceptOnlyNecessaryCookieBtnCallback())
    }
  }

  private handleCookieConsentEventDispatch(
    cookiePolicyConfig: CookiePolicyConfig,
    cookieConsentEventBody: CookieConsentEventBody
  ): void {
    if (cookiePolicyConfig.enableConfigure) {
      dispatchCookieConsentEvent(cookieConsentEventBody)
    }
  }

  private acceptAllCookies(): void {
    const cookieConsentPreferences = {
      consentGiven: true,
      allowAdvertising: true
    }
    this.consentService.setupCookieConsent(cookieConsentPreferences)
    this.root.remove()
  }

  private acceptOnlyNecessaryCookies(): void {
    const cookieConsentPreferences = {
      consentGiven: true,
      allowAdvertising: this.keepAllowAdvertising
    }
    this.consentService.setupCookieConsent(cookieConsentPreferences)
    this.root.remove()
  }
}
