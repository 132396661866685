export type CookieShowEventBody = {
  enableConfigure: boolean
}

export type CookieConsentEventBody = {
  allowAdvertising: boolean
  enableConfigure: boolean
}

export type CookieSettingsEventBody = {
  enableSettings: boolean
}

const dispatchCookieShowEvent = ({ enableConfigure }: CookieShowEventBody): void => {
  const event = new CustomEvent<CookieShowEventBody>('cookieshow', {
    detail: {
      enableConfigure
    }
  })

  document.dispatchEvent(event)
}

const dispatchCookieConsentEvent = ({ allowAdvertising, enableConfigure }: CookieConsentEventBody): void => {
  const event = new CustomEvent<CookieConsentEventBody>('cookieconsent', {
    detail: {
      allowAdvertising,
      enableConfigure
    }
  })

  document.dispatchEvent(event)
}

const dispatchCookieSettingsEvent = ({ enableSettings }: CookieSettingsEventBody): void => {
  const event = new CustomEvent<CookieSettingsEventBody>('cookiesettings', {
    detail: {
      enableSettings
    }
  })

  document.dispatchEvent(event)
}

export { dispatchCookieShowEvent, dispatchCookieConsentEvent, dispatchCookieSettingsEvent }
