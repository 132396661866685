
      import API from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../node_modules/css-loader/dist/cjs.js!./loader.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = function insert(style) {
            var container = document.querySelector('div#hotmart-cookie-policy');
            if (container) {
              var _container$shadowRoot;
              (_container$shadowRoot = container.shadowRoot) === null || _container$shadowRoot === void 0 || _container$shadowRoot.append(style);
            } else {
              document.head.append(style);
            }
          };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../node_modules/css-loader/dist/cjs.js!./loader.css";
       export default content && content.locals ? content.locals : undefined;
