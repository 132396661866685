import Cookies from 'js-cookie'

import { COMPANY, possibleScriptNames } from 'common/constants'

import PT from './resources/pt_BR.json'
import AR from './transifex/ar.json'
import DE from './transifex/de.json'
import EN from './transifex/en.json'
import ES from './transifex/es.json'
import FR from './transifex/fr.json'
import IT from './transifex/it.json'
import JA from './transifex/ja.json'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const resources: Record<string, any> = {
  AR,
  DE,
  EN,
  ES,
  FR,
  IT,
  JA,
  PT
}

let currentLanguage = 'EN'

const isRtlResource = (): boolean => {
  const rtlResources = ['AR']

  if (rtlResources.includes(currentLanguage)) {
    return true
  }

  return false
}

const setLang = (staticLanguage?: string): void => {
  if (staticLanguage && Object.keys(resources).includes(staticLanguage.toUpperCase())) {
    currentLanguage = staticLanguage.toUpperCase()
  } else {
    const langCookie = Cookies.get('hmLangCookie')
    const language = langCookie || navigator.language
    currentLanguage = language.substr(0, 2).toUpperCase()
  }
}

const getLang = (): string => currentLanguage

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const t = (label: string, replaceOptions?: any): any => {
  const resource = resources[currentLanguage] ? resources[currentLanguage] : resources.EN

  if (replaceOptions) {
    let result = resource[label]

    for (const [key, value] of Object.entries(replaceOptions)) {
      result = result.replace(`{{${key}}}`, value)
    }
    return result
  }
  return resource[label]
}

const getInitialLanguage = (company: COMPANY, scripts?: HTMLScriptElement[]): string | undefined => {
  if (!scripts) {
    return undefined
  }

  const cookieBannerScript = scripts.find((script) => {
    const scriptSrc = script.getAttribute('src')

    if (process.env.APP_STAGE === 'development') {
      return scriptSrc?.includes('app.js')
    }

    return possibleScriptNames[company].find((possibleScriptName) => scriptSrc?.includes(possibleScriptName))
  })

  const scriptLangParameter = 'lang='

  if (!cookieBannerScript) {
    return undefined
  }

  const scriptSrc = cookieBannerScript.getAttribute('src')
  const langIndexOf = scriptSrc?.indexOf(scriptLangParameter)

  if (!langIndexOf || langIndexOf === -1) {
    return undefined
  }

  const initialPosition = langIndexOf + scriptLangParameter.length

  return scriptSrc?.slice(initialPosition, initialPosition + 2)
}

export { setLang, t, isRtlResource, getInitialLanguage, getLang }
