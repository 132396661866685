import Cookies from 'js-cookie'

import { COOKIE_AB_EXPIRES, COOKIE_CONSENT_VERSION, TWELVE_MONTHS_IN_DAYS } from 'common/constants'
import { CookieConsentPreferencesType } from 'common/models/cookie-consent-preferences'
import { DomainService } from 'common/service/DomainService'

type WindowLocation = { hostname: string }

export const INITIAL_COOKIE_PREFERENCES: CookieConsentPreferencesType = {
  consentGiven: false,
  consentDate: new Date(),
  allowAdvertising: false,
  version: COOKIE_CONSENT_VERSION
}

export class ConsentService {
  cookieName: string
  currentDate: number = Date.now()
  domain: string
  isTestAb: boolean
  constructor(private location: WindowLocation, private domainService: DomainService, private testAb?: boolean) {
    this.cookieName = this.domainService.getCookieNameByDomain(this.location.hostname)
    this.domain = this.domainService.getDomainName(this.location.hostname)
    this.isTestAb = this.testAb || false
  }

  getConsent(): CookieConsentPreferencesType | undefined {
    const cookieConsent = Cookies.get(this.cookieName)
    if (cookieConsent) {
      return JSON.parse(cookieConsent) as CookieConsentPreferencesType
    }
    return undefined
  }

  thereIs(): boolean {
    return Boolean(this.getConsent())
  }

  initialSetup(): void {
    this.setupCookieConsent(INITIAL_COOKIE_PREFERENCES)
  }

  setupCookieConsent(cookiePreferences: Omit<CookieConsentPreferencesType, 'consentDate' | 'version'>): void {
    const consent: CookieConsentPreferencesType = {
      ...cookiePreferences,
      consentDate: new Date(),
      version: COOKIE_CONSENT_VERSION
    }

    Cookies.set(this.cookieName, JSON.stringify(consent), {
      expires: this.isTestAb ? COOKIE_AB_EXPIRES : TWELVE_MONTHS_IN_DAYS,
      domain: this.domainService.getDomainName(this.location.hostname)
    })
  }
}
