import { COOKIE_CATEGORY } from 'common/constants'
import { Cookie } from 'common/models/cookie-category'
import { CookieConsentPreferencesType } from 'common/models/cookie-consent-preferences'
import ConfirmModalBase from 'cookie-banner/base/ConfirmModalBase'
import { t } from 'cookie-banner/i18n'
import { loadCosmosModal } from 'cookie-banner/styles'

export class HotmartConfirmModal extends ConfirmModalBase {
  override async loadCompanyStyle(): Promise<void> {
    await loadCosmosModal()
  }

  protected afterHotmartModalRender?(): void

  async render(): Promise<void> {
    const consent = this.consentService.getConsent() || ({} as CookieConsentPreferencesType)

    const isRtl = this.isRtl
    await require('cookie-banner/styles/loader.css')

    this.root.innerHTML = `
      <hot-modal-header>
        <h3 class='_text-4 _text-gray-700 _mb-0 _font-weight-light'>${t('modal_title')}</h3>
      </hot-modal-header>
      <hot-modal-body style="max-height: 70vh; display: flex; flex-direction: column; overflow: auto;">
        <p>${t('modal_description')}</p>

        <hot-list-group>
          <hot-list-group-item class='hot-list-group__item--action'>
            <hot-collapse id="essential">
              <div class='hot-form hot-form--custom hot-form--switch _mb-0 ${isRtl ? '_pr-5 _mr-4' : '_pl-5'}'>
                <input type='checkbox' class='hot-form__input' checked disabled id='cookie-essential-switch' />
                <label class='hot-form__label' for='cookie-essential-switch'>${t('modal_essential_title')}</label>
              </div>
              <div slot='collapsed'>
                <p>
                  ${t('modal_essential_description')}
                </p>

                <div class="loader loader--essential"></div>
                <div class="cookie-list-essential"></div>
              </div>
            </hot-collapse>
          </hot-list-group-item>
          <hot-list-group-item class='hot-list-group__item--action'>
            <hot-collapse id="advertising">
              <div class='hot-form hot-form--custom hot-form--switch _mb-0 ${isRtl ? '_pr-5 _mr-4' : '_pl-5'}'>
                <input type='checkbox' class='hot-form__input cookie-advertising-input' id='cookie-advertising-input' ${
                  consent.allowAdvertising ? 'checked' : ''
                }  />
                <label class='hot-form__label' for='cookie-advertising-input'>${t('modal_advertising_title')}</label>
              </div>
              <div slot='collapsed' style="display: flex; flex-direction: column; overflow: auto; cursor: default">
                <p>
                  ${t('modal_advertising_description')}
                </p>

                <div class="loader loader--advertising"></div>
                <div class="cookie-list-advertising"></div>
              </div>
            </hot-collapse>
          </hot-list-group-item>
        </hot-list-group>
      </hot-modal-body>
      <hot-modal-footer>
        <button class='hot-button ${isRtl ? '_mr-auto' : '_ml-auto'} hot-button--primary cookie-policy-confirm-btn'>${t(
      'modal_confirm_btn'
    )}</button>
      </hot-modal-footer>
    `
  }

  protected cookieListInnerHTML = (cookieListFromCategory: Cookie[]): string => {
    let result = `
    <div class="cookie-banner-category-table">
      <div class="cookie-banner-category-table-header">
        <span>${t('modal_cookie_list_name')}</span>
        <span>${t('modal_cookie_list_domain')}</span>
      </div>
    `

    cookieListFromCategory.forEach((cookie) => {
      result += `
        <div class="cookie-banner-category-table-body">
          <hot-collapse class="hot-collapse--arrow-start">
            <div class="cookie-banner-category-table-body--item">
              <span>${cookie.name}</span>
              <span>${cookie.domain}</span>
            </div>

            <div slot='collapsed' style="padding: var(--table-padding);">
              <div style="padding-left: 28px">
                <span>${cookie.description}</span>
              </div>

            </div>
          </hot-collapse>
        </div>
      `
    })

    result += `</div>`

    return result
  }

  protected afterRender = (): void => {
    const newRootStyle = document.createElement('style')
    const newHeaderStyle = document.createElement('style')
    const newBodyStyle = document.createElement('style')

    newRootStyle.innerHTML = `
      :host {
        --modal-max-width: 720px;
        --modal-padding: 1.5rem;
        --border-radius: 0.5rem;

        --loader-background: var(--gray-200);
        --loader-color: var(--primary);
      }
    `

    newHeaderStyle.innerHTML = `
      :host {
        padding-bottom: calc(var(--modal-padding)/2)
      }

      .hot-collapse__item .hot-collapse__title {
        padding-right: 0px !important
      }
    `

    newBodyStyle.innerHTML = `
      :host {
        --collapse-padding: var(--spacer-2) 0px var(--spacer-2) var(--spacer-4);
        padding: 0 var(--spacer-5)
      }

      div.cookie-banner-category-table-wrapper {
        margin-top: var(--spacer-4);
        margin-left: var(--spacer-4);
      }

      hot-list-group-item.hot-list-group__item--action:hover {
        background: var(--white);
      }

      .hot-button-toggle-description {
        margin-right: var(--spacer-2);
      }

      .cookie-banner-category-table-header {
        display: grid;
        grid-template-columns: 200px auto;
      }

      .cookie-banner-category-table-header span {
        color: var(--gray-500);
        font-weight: bold;
        font-size: var(--text-1);
        margin-bottom: var(--spacer-2);
      }

      .cookie-banner-category-table-header:first-child {
        padding-left: 44px;
      }

      .cookie-banner-category-table-body {
        background-color: var(--gray-100);
        border-radius: var(--border-radius);
        margin-bottom: var(--spacer-2);
        font-size: var(--text-1);
      }

      .cookie-banner-category-table-body:hover {
        background-color: var(--gray-200);
      }
      
      .cookie-banner-category-table-body--item {
        display: grid;
        grid-template-columns: 200px auto;
      }
    `

    this.root?.appendChild(newRootStyle)
    const hotModalHeader = this.root.querySelector('hot-modal-header')
    hotModalHeader?.appendChild(newHeaderStyle)
    const hotModalBody = this.root.querySelector('hot-modal-body')
    hotModalBody?.appendChild(newBodyStyle)

    this.resetHotCollapseCss()
    this.addCookieListFetchListeners()

    this.afterHotmartModalRender && this.afterHotmartModalRender()
  }

  protected addCookieListFetchListeners(): void {
    let cookieListEssential: Cookie[]
    let cookieListAdvertise: Cookie[]

    const fetchCookieCallback = async (category: COOKIE_CATEGORY): Promise<void> => {
      let cookieList: Cookie[] = []

      try {
        if (category === COOKIE_CATEGORY.ESSENTIAL && !cookieListEssential) {
          cookieList = await this.fetchCookieList(category)
          cookieListEssential = [...cookieList]
        }

        if (category === COOKIE_CATEGORY.ADVERTISING && !cookieListAdvertise) {
          cookieList = await this.fetchCookieList(category)
          cookieListAdvertise = [...cookieList]
        }
      } finally {
        const loader = this.root.querySelector(`.loader.loader--${category.toLowerCase()}`) as HTMLDivElement
        loader.setAttribute('hidden', '')
      }

      if (cookieList.length) {
        const cookieListWrapper = this.root.querySelector(`div.cookie-list-${category.toLowerCase()}`) as HTMLDivElement
        cookieListWrapper.innerHTML = this.cookieListInnerHTML(cookieList)

        this.resetHotCollapseCss()
      }
    }

    Object.values(COOKIE_CATEGORY).forEach((category) => {
      const hotCollapse = this.root.querySelector(`hot-collapse#${category.toLowerCase()}`) as Element

      hotCollapse.addEventListener('open', () => fetchCookieCallback(category))
    })
  }

  private resetHotCollapseCss(): void {
    const hotModalBody = this.root.querySelector('hot-modal-body') as HTMLDivElement

    const collapseList = hotModalBody.querySelectorAll('hot-collapse')
    collapseList.forEach((collapseElement) => {
      const newCollapseStyle = document.createElement('style')

      newCollapseStyle.innerHTML = `
      :host .hot-collapse__item:hover {
        background-color: transparent
      }
    `

      collapseElement.shadowRoot?.appendChild(newCollapseStyle)
    })
  }
}
