import { CookiePolicy } from 'common/models/cookie-policy-config'

import { API_COOKIE, COMPANY } from './constants'
import { CookieCrawler } from './models/cookie-crawler'

const postBrowserCookies = (company: COMPANY): Promise<Response> =>
  fetch(`${API_COOKIE}/v2/crawler`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      company
    },
    body: JSON.stringify({
      url: window.location.href
    })
  })

const getCrawlerInfo = async (): Promise<CookieCrawler> => {
  const response = await fetch(`${API_COOKIE}/crawler`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })

  return response.json()
}

const getCookiePolicyByCountry = async (countryCode: string): Promise<CookiePolicy> => {
  const response = await fetch(`${API_COOKIE}/cookie-policies/${countryCode}`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })

  return response.json()
}

export { postBrowserCookies, getCrawlerInfo, getCookiePolicyByCountry }
