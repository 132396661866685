import { COMPANY, COOKIE_CONSENT_VERSION, COOKIE_PACKAGE_VERSION } from 'common/constants'
import { dispatchCookieConsentEvent } from 'common/event'
import { ConsentService, INITIAL_COOKIE_PREFERENCES } from 'common/service/ConsentService'
import { CookieCategorizationService } from 'common/service/CookieCategorizationService'
import { CookiePolicyService } from 'common/service/CookiePolicyService'
import { DomainService } from 'common/service/DomainService'
import { UrlBlockedListService } from 'common/service/UrlBlockedListService'
import CookieBanner from 'cookie-banner'
import { default as initCrawler } from 'cookie-crawler'

export class App {
  constructor(
    private banner: CookieBanner,
    private consentService: ConsentService,
    private cookieCategorizationService: CookieCategorizationService,
    private cookiePolicyService: CookiePolicyService,
    private urlBlockedListService: UrlBlockedListService,
    private domainService: DomainService,
    private company: COMPANY
  ) {}

  setVersion(): void {
    window.hmCookieBannerVersion = {
      COOKIE_CONSENT_VERSION,
      COOKIE_PACKAGE_VERSION
    }
  }

  async run(): Promise<void> {
    this.setVersion()
    const domain = document.domain

    const isAllowedUrl = this.urlBlockedListService.canShowBanner()
    const isAllowedDomain = this.domainService.availableDomain(domain)

    const canShowBanner = isAllowedUrl && isAllowedDomain
    if (canShowBanner) {
      await initCrawler(new Date(), this.company)
      this.banner.createUpdateCookieApplication()

      const cookieSettingsDomain = this.domainService.getCookieSettingsNameByDomain(domain)
      const cookieConsentDomain = this.domainService.getCookieNameByDomain(domain)
      const policy = await this.cookiePolicyService.getCookiePolicy(cookieSettingsDomain, cookieConsentDomain, domain)

      if (this.banner.isCookiePolicyConsentGiven()) {
        this.banner.createReshowCookieNoticeFunction(policy.config, this.company)
        await this.cookieCategorizationService.refreshCookieCategorizationChanges()
        return
      }

      if (policy.config.enableCookieBanner) {
        await this.banner.render(policy.config, this.company)
        this.banner.createReshowCookieNoticeFunction(policy.config, this.company)
        await this.cookieCategorizationService.refreshCookieCategorizationChanges()
      } else {
        this.consentService.setupCookieConsent({
          ...INITIAL_COOKIE_PREFERENCES,
          allowAdvertising: true
        })

        dispatchCookieConsentEvent({
          allowAdvertising: true,
          enableConfigure: policy.config.enableConfigure
        })
      }
    } else {
      console.warn('Cookie consent not allowed for current URL / domain')
    }
  }
}
