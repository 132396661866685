import { COMPANY, COOKIE_CATCHER } from 'common/constants'
import { getCrawlerInfo, postBrowserCookies } from 'common/request'

import { buildDateDaysBefore, isProcessDateMoreThanAWeek, processingCatcher, registerProcessDate } from './crawlerUtils'

const init = async (currentDate: Date, company: COMPANY): Promise<void> => {
  const localData = localStorage.getItem(COOKIE_CATCHER)
  const cookieCatcherConfig = localData
    ? JSON.parse(localData)
    : { lastProcessDateTime: buildDateDaysBefore(currentDate, 8).toISOString() }

  await processingCatcher({
    config: cookieCatcherConfig,
    timeCheck: isProcessDateMoreThanAWeek,
    getInfo: getCrawlerInfo,
    postCookies: () => postBrowserCookies(company),
    saveProcessLocal: registerProcessDate
  })
}

export default init
