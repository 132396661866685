import BannerContainerBase from 'cookie-banner/base/BannerContainerBase'

export class HotmartCoBannerContainer extends BannerContainerBase {
  override loadFont(): void {
    if (!document.fonts.check('16px Poppins')) {
      this.container.innerHTML =
        '<link rel="stylesheet" href="https://irp.cdn-website.com/fonts/css?family=Poppins:300,400,700&amp;subset=latin-ext&amp;display=swap" as="style" fetchpriority="low"  type="text/css">'
    }
  }
}

customElements.define('hotmartco-cookie-policy', HotmartCoBannerContainer)
