import Cookies from 'js-cookie'

import { API_COOKIE, COMPANY, RESEARCH_CATEGORIZATION } from 'common/constants'
import { CookieChanges } from 'common/models/cookie-changes'
import { ConsentService } from 'common/service/ConsentService'
import { LocationService } from 'common/service/LocationService'

export class CookieCategorizationService {
  locationService: LocationService
  company: COMPANY
  currentDate: number = Date.now()
  constructor(private consentService: ConsentService, company: COMPANY, service: LocationService) {
    this.company = company
    this.locationService = service
  }

  setCookieCategorizationChanges(lastRecategory: number): void {
    window.localStorage.setItem('lastCookieCategorizationChanges', String(lastRecategory))
  }

  hasCookieCategorizationChanges(): boolean {
    const lastChanges: string | null = window.localStorage.getItem('lastCookieCategorizationChanges')
    if (lastChanges) {
      const lastDate: number = parseInt(lastChanges)
      return this.currentDate >= lastDate + RESEARCH_CATEGORIZATION
    }
    this.setCookieCategorizationChanges(this.currentDate)
    return false
  }

  async refreshCookieCategorizationChanges(): Promise<void> {
    const countryCode = await this.locationService.getCountry()
    if (this.hasCookieCategorizationChanges()) {
      try {
        const cookieChanges: CookieChanges = await this.getCookieCategorizationChanges(this.company, countryCode)
        if (cookieChanges.hasChange) {
          Cookies.set(this.consentService.cookieName, '', {
            expires: 0,
            domain: this.consentService.domain
          })
          window.hmReshowCookieNotice()
          this.consentService.initialSetup()
        }
        this.setCookieCategorizationChanges(this.currentDate)
      } catch {
        return
      }
    }
  }

  async getCookieCategorizationChanges(company: COMPANY, country: string): Promise<CookieChanges> {
    const response = await fetch(`${API_COOKIE}/cookies/changes?countryCode=${country}`, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        company
      }
    })

    return response.json()
  }
}
