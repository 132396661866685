import { App } from 'app'
import { COMPANY } from 'common/constants'
import { ConsentService } from 'common/service/ConsentService'
import { CookieCategorizationService } from 'common/service/CookieCategorizationService'
import { CookiePolicyService } from 'common/service/CookiePolicyService'
import { CountryService } from 'common/service/CountryService'
import { DomainService } from 'common/service/DomainService'
import { UrlBlockedListService } from 'common/service/UrlBlockedListService'
import CookieBanner from 'cookie-banner'
import AlertBase from 'cookie-banner/base/AlertBase'
import BannerContainerBase from 'cookie-banner/base/BannerContainerBase'
import ConfirmModalBase from 'cookie-banner/base/ConfirmModalBase'

export const createApp = (
  consentService: ConsentService,
  container: BannerContainerBase,
  alert: AlertBase,
  modal: ConfirmModalBase,
  company: COMPANY
): App => {
  const cookieCategorizationService = new CookieCategorizationService(consentService, company, new CountryService())
  const cookiePolicyService = new CookiePolicyService(new CountryService())
  const urlBlockedListService = new UrlBlockedListService()
  const domainService = new DomainService()

  const banner = new CookieBanner(consentService, container, alert, modal)

  return new App(
    banner,
    consentService,
    cookieCategorizationService,
    cookiePolicyService,
    urlBlockedListService,
    domainService,
    company
  )
}
