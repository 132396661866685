import { IP_API } from 'common/constants'
import { LocationService } from 'common/service/LocationService'

export class CountryService implements LocationService {
  async getCountry(): Promise<string> {
    try {
      const response = await fetch(IP_API)
      const data = await response.json()

      return data.country
    } catch {
      return 'unknown'
    }
  }
}
