import { sendAgentEvent } from 'common/agent'
import { COMPANY } from 'common/constants'
import { dispatchCookieConsentEvent, dispatchCookieShowEvent } from 'common/event'
import { CookiePolicyConfig } from 'common/models/cookie-policy-config'
import { ConsentService, INITIAL_COOKIE_PREFERENCES } from 'common/service/ConsentService'
import AlertBase from 'cookie-banner/base/AlertBase'
import BannerContainerBase from 'cookie-banner/base/BannerContainerBase'
import ConfirmModalBase from 'cookie-banner/base/ConfirmModalBase'
import { getInitialLanguage, setLang } from 'cookie-banner/i18n'

export default class CookieBanner {
  constructor(
    private consentService: ConsentService,
    private containerComponent: BannerContainerBase,
    private alertComponent: AlertBase,
    private confirmModalComponent?: ConfirmModalBase
  ) {}

  private openModalConfiguration = async (): Promise<void> => {
    if (this.confirmModalComponent) {
      const modalComponent = await this.confirmModalComponent.build()
      this.containerComponent.addChild(modalComponent)
      this.confirmModalComponent.openModal()
    }
  }

  private createAlertComponent = async (cookiePolicyConfig: CookiePolicyConfig, language?: string): Promise<void> => {
    setLang(language)
    const alertContent = await this.alertComponent.build({
      cookiePolicyConfig,
      openModalCb: () => this.openModalConfiguration()
    })
    this.containerComponent.addChild(alertContent)
    dispatchCookieShowEvent({
      enableConfigure: cookiePolicyConfig.enableConfigure
    })

    sendAgentEvent({
      action: 'cookie_show_application',
      allowAdvertising: cookiePolicyConfig.noEssentialCookiesEnableByDefault,
      enabledConfigure: cookiePolicyConfig.enableConfigure
    })
  }

  private createUpdateLanguageFunction = (cookiePolicyConfig: CookiePolicyConfig): void => {
    window.hmUpdateCookieLanguage = async (language: string): Promise<void> => {
      if (language.length >= 2) {
        const container = document.getElementById('hotmart-cookie-policy')

        if (container) {
          const component = container.shadowRoot?.getElementById('hotmart-cookie_alert-component')

          if (component) {
            container.shadowRoot?.removeChild(component)
            await this.createAlertComponent(cookiePolicyConfig, language.slice(0, 2))
          }
        }
      }
    }
  }

  createUpdateCookieApplication = (): void => {
    window.hmUpdateCookieApplication = (projectName: string, sessionId?: string): void => {
      window.localStorage.setItem('cookie-application', JSON.stringify({ projectName, sessionId }))
    }
  }

  isCookiePolicyConsentGiven(): boolean {
    const cookieConsent = this.consentService.getConsent()
    return cookieConsent ? cookieConsent.consentGiven : false
  }

  async render(cookiePolicyConfig: CookiePolicyConfig, company: COMPANY): Promise<HTMLDivElement> {
    if (!this.consentService.thereIs()) {
      this.consentService.initialSetup()
    }

    if (cookiePolicyConfig.noEssentialCookiesEnableByDefault || !cookiePolicyConfig.enableConfigure) {
      this.consentService.setupCookieConsent({
        ...INITIAL_COOKIE_PREFERENCES,
        allowAdvertising: true
      })

      dispatchCookieConsentEvent({
        allowAdvertising: true,
        enableConfigure: cookiePolicyConfig.enableConfigure
      })
    }

    if (!this.confirmModalComponent) {
      cookiePolicyConfig = { ...cookiePolicyConfig, enableConfigure: false }
    }

    document.body.prepend(this.containerComponent)

    const initialLanguage = getInitialLanguage(company, Array.from(document.scripts))

    await this.createAlertComponent(cookiePolicyConfig, initialLanguage)
    this.createUpdateLanguageFunction(cookiePolicyConfig)

    return this.containerComponent.getContainer()
  }

  createReshowCookieNoticeFunction = (cookiePolicyConfig: CookiePolicyConfig, company: COMPANY): void => {
    window.hmReshowCookieNotice = async (): Promise<void> => {
      const container = document.getElementById('hotmart-cookie-policy')

      if (container) {
        const component = container.shadowRoot?.getElementById('hotmart-cookie_alert-component')

        if (!component) {
          const initialLanguage = getInitialLanguage(company, Array.from(document.scripts))
          await this.createAlertComponent(cookiePolicyConfig, initialLanguage)
        }
      } else {
        this.render(cookiePolicyConfig, company)
      }
    }
  }
}
