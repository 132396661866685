import { sendAgentEvent } from 'common/agent'
import { COOKIE_CATEGORY } from 'common/constants'
import { dispatchCookieConsentEvent } from 'common/event'
import { Cookie } from 'common/models/cookie-category'
import { CookieConsentPreferencesType } from 'common/models/cookie-consent-preferences'
import { ConsentService } from 'common/service/ConsentService'
import { CookieService } from 'common/service/CookieService'
import { isRtlResource, t } from 'cookie-banner/i18n'

export default abstract class ConfirmModalBase {
  protected isRtl: boolean
  protected root: HTMLElement

  constructor(
    protected consentService: ConsentService,
    private cookieService: CookieService,
    private keepAllowAdvertising = false
  ) {
    this.root = document.createElement('hot-modal')
    this.isRtl = isRtlResource()
    this.root.setAttribute('position', 'centered')
    this.root.setAttribute('open', 'true')
    this.root.id = 'hotmart-cookie-modal-configuration'
    this.root.dir = this.isRtl ? 'rtl' : 'ltr'
  }

  async build(): Promise<HTMLElement> {
    this.loadCompanyStyle && (await this.loadCompanyStyle())
    await this.render()
    this.configureHandlers()
    this.afterRender && this.afterRender()
    return this.root
  }

  protected loadCompanyStyle?(): Promise<void>

  async render(): Promise<void> {
    const consent = this.consentService.getConsent() || ({} as CookieConsentPreferencesType)

    this.root.classList.add('modal')
    this.root.classList.add('hotmart-cookie-modal-configuration')
    await require('cookie-banner/styles/base-modal.css')
    await require('cookie-banner/styles/loader.css')

    this.root.innerHTML = `
      <div class='cookie-banner-modal-header'>
        <h3 class='cookie-banner-modal-title' >${t('modal_title')}</h3>
      </div>
      <div class='cookie-banner-modal-body'>
        <p class='cookie-banner-modal-description' >${t('modal_description')}</p>

        <ul class='cookie-banner-categories-list'>
          <li class='cookie-banner-categories-item'>
            <div>
              <div class='cookie-banner-category-form'>
                <input type='checkbox' class='cookie-banner-category-form-input' checked disabled id='cookie-essential-switch' />
                <label class='cookie-banner-category-form-label' for='cookie-essential-switch'>${t(
                  'modal_essential_title'
                )}</label>
              </div>
              <div class='cookie-banner-category-description' >${t('modal_essential_description')}</div>

              <div class="loader loader--essential"></div>
              <div class="cookie-list-essential"></div>
            </div>
          </li>
          <li class='cookie-banner-categories-item'>
            <div>
              <div class='cookie-banner-category-form'>
                <input type='checkbox' class='cookie-banner-category-form-input cookie-advertising-input' id='cookie-advertising-input' ${
                  consent.allowAdvertising ? 'checked' : ''
                }  />
                <label class='cookie-banner-category-form-label' for='cookie-advertising-input'>${t(
                  'modal_advertising_title'
                )}</label>
              </div>
              <div class='cookie-banner-category-description' >${t('modal_advertising_description')}</div>

              <div class="loader loader--advertising"></div>
              <div class="cookie-list-advertising"></div>
            </div>
          </li>
        </ul>
      </div>
      <div class='cookie-banner-modal-footer' >
        <button class='cookie-policy-button cookie-policy-confirm-btn'>
          ${t('modal_confirm_btn')}
        </button>
      </div>
    `
  }

  openModal(): void {
    const container = document.getElementById('hotmart-cookie-policy')
    if (container) {
      const hotModal = container.shadowRoot?.getElementById('hotmart-cookie-modal-configuration')
      if (hotModal) {
        hotModal.setAttribute('open', 'true')
        hotModal.addEventListener('close', this.reshowAfterClose)
      }
    }
  }

  reshowAfterClose(): void {
    window.hmReshowCookieNotice()
    sendAgentEvent({
      action: 'cookie_close_preferences',
      allowAdvertising: false,
      enabledConfigure: true
    })
  }

  protected afterRender(): void {
    Object.values(COOKIE_CATEGORY).forEach((category) => {
      this.fetchCookieList(category)
        .then((cookieList) => {
          if (cookieList.length) {
            const cookieListWrapper = this.root.querySelector(
              `div.cookie-list-${category.toLowerCase()}`
            ) as HTMLDivElement

            cookieListWrapper.innerHTML = this.cookieListInnerHTML(cookieList)
          }
        })
        .finally(() => {
          const loader = this.root.querySelector(`.loader.loader--${category.toLowerCase()}`) as HTMLDivElement
          loader.setAttribute('hidden', '')
        })
    })
  }

  protected cookieListInnerHTML(cookieListFromCategory: Cookie[]): string {
    let result = `
    <table class="cookie-banner-category-table" width="100%">
      <thead>
        <th>${t('modal_cookie_list_name')}</th>
        <th>${t('modal_cookie_list_domain')}</th>
        <th>${t('modal_cookie_list_description')}</th>
      </thead>
    `

    cookieListFromCategory.forEach((cookie) => {
      result += `
        <tbody>
          <tr>
            <td>${cookie.name}</td>
            <td>${cookie.domain}</td>
            <td>${cookie.description}</td>
          </tr>
        </tbody>
      `
    })

    result += `</table>`

    return result
  }

  protected async fetchCookieList(category: COOKIE_CATEGORY): Promise<Cookie[]> {
    return this.cookieService.getCookieList(category)
  }

  private configureHandlers(): void {
    const confirmBtn = this.root.getElementsByClassName('cookie-policy-confirm-btn')[0]
    confirmBtn.addEventListener('click', () => this.confirmCookiePreferences())
  }

  private confirmCookiePreferences = (): void => {
    const element = this.root.getElementsByClassName('cookie-advertising-input').item(0) as HTMLInputElement
    const advertisingCookieValue = element?.checked

    const cookieConsentPreferences = {
      consentGiven: true,
      allowAdvertising: this.keepAllowAdvertising ? true : advertisingCookieValue
    }

    dispatchCookieConsentEvent({
      allowAdvertising: cookieConsentPreferences.allowAdvertising,
      enableConfigure: true
    })

    sendAgentEvent({
      action: 'cookie_acceptance_preferences',
      allowAdvertising: cookieConsentPreferences.allowAdvertising,
      enabledConfigure: true,
      acceptedDate: new Date().toISOString()
    })

    this.consentService.setupCookieConsent(cookieConsentPreferences)
    this.root.remove()
  }
}
