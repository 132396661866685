// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#hotmart-cookie_alert-component.hotmart-cookie-small-version {
  max-width: 360px;
  margin: 0;
  margin-left: auto;
  padding: var(--spacer-3);
}

#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer button {
  width: 100%;
}

#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer button:not(last-of-type) {
  margin-left: 0;
  margin-right: 0;
}

#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer button:not(.hidden) ~ button {
  margin-top: var(--spacer-3);
}

#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer {
  flex-direction: column;
}

@media all and (max-width: 576px) {
  #hotmart-cookie_alert-component.hotmart-cookie-small-version {
    max-width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/cookie-banner/implementations/hotpay/page-small.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,SAAS;EACT,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["#hotmart-cookie_alert-component.hotmart-cookie-small-version {\n  max-width: 360px;\n  margin: 0;\n  margin-left: auto;\n  padding: var(--spacer-3);\n}\n\n#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer button {\n  width: 100%;\n}\n\n#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer button:not(last-of-type) {\n  margin-left: 0;\n  margin-right: 0;\n}\n\n#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer button:not(.hidden) ~ button {\n  margin-top: var(--spacer-3);\n}\n\n#hotmart-cookie_alert-component.hotmart-cookie-small-version .cookie-policy-footer {\n  flex-direction: column;\n}\n\n@media all and (max-width: 576px) {\n  #hotmart-cookie_alert-component.hotmart-cookie-small-version {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
